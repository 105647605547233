import { useEffect, useState } from "react";
import RegistrantProfileForm from "../../forms/RegistrantProfileForm";
import { isUserCompleted } from "src/utils/jwtFunctions";

const RegistrantProfilePage = () => {
    const [isUpdate, setIsUpdate] = useState<boolean>(false);

    useEffect(() => {
        if (isUserCompleted()) {
            setIsUpdate(true);
        }
    }, []);

    return <RegistrantProfileForm isUpdate={isUpdate} />;
};

export default RegistrantProfilePage;
