import jwtDecode from "jwt-decode";
import axios from "axios";

export enum TokenTypeEnum {
    AUTH = "authToken",
    REFRESH = "refreshToken",
}

export const getToken = (tokenType: TokenTypeEnum) => {
    const token = localStorage.getItem(tokenType);
    return token || null;
};

export const isUserCompleted = () => {
    const authToken = getToken(TokenTypeEnum.AUTH);

    if (!authToken) {
        return false;
    }

    const decodedToken: any = jwtDecode(authToken);

    return decodedToken.isCompleted;
};

export const verifyToken = (token: string) => {
    const decodedToken: any = jwtDecode(token);
    if (decodedToken && decodedToken?.exp)
        return decodedToken.exp > Date.now() / 1000;
    return false;
};

export const handleTokenStorage = (
    mode: "SET" | "REM",
    tokenType: TokenTypeEnum,
    token?: string,
) => {
    if (mode === "SET" && token) {
        localStorage.setItem(tokenType, token);
    } else {
        localStorage.removeItem(tokenType);
    }
};

export const refreshAuthToken = async (): Promise<string | null> => {
    const refreshToken = getToken(TokenTypeEnum.REFRESH);
    if (refreshToken) {
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/token`,
                {
                    refreshToken,
                },
            );
            if (result.data && result.data?.success) {
                const newAccessToken = result.data.accessToken;
                handleTokenStorage("SET", TokenTypeEnum.AUTH, newAccessToken);
                return newAccessToken;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    } else {
        return null;
    }
};
