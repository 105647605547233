import { Fragment, useMemo, useState } from "react";
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    headerStyle,
    formLabelStyle,
    signInFormContainer,
    formSubmitButtonStyle,
    registerTextInputStyle,
} from "./Form.styles";
import { regExValidations } from "../data/validators";
import useMessage from "src/hooks/useMessage";
import SpinnerLoader from "src/components/loader/SpinnerLoader";
import {
    emailRegex,
    getAuthPageContainerStyle,
    passwordRegex,
} from "src/utils/helperFunctions";
import PasswordField from "src/components/input/PasswordTextField";

function SignUpForm() {
    const navigate = useNavigate();
    const { showSuccess, showError } = useMessage();

    // sign Up Page General State
    const [registrationLoading, setRegistrationLoading] = useState(false);
    const containerStyle = useMemo(() => getAuthPageContainerStyle(window), []);

    // Form Validation

    const validationSchema = yup.object({
        email: yup
            .string()
            .matches(emailRegex, "Invalid email format")
            .required("Email is required"),
        username: yup
            .string()
            .matches(regExValidations.username, "Invalid characters")
            .required("Username is required"),
        password: yup
            .string()
            .test(
                "password",
                "Your password should include minimum 6 characters including uppercase, lowercase, numerical and special character",
                function (value) {
                    return passwordRegex.test(value as string);
                },
            )
            .required("Password is required"),
        confirmPassword: yup
            .string()
            .required("Confirm Password is required")
            .test(
                "passwords-match",
                "Passwords did not match",
                function (value) {
                    return this.parent.password === value;
                },
            ),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            username: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema,
        async onSubmit(values) {
            setRegistrationLoading(true);
            try {
                const url = process.env.REACT_APP_BACKEND_URL + "/register";
                await axios.post(
                    url,
                    JSON.stringify({
                        username: values.username,
                        email: values.email,
                        password: values.password,
                        confirmPassword: values.confirmPassword,
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    },
                );
                showSuccess("User registered successfully");

                setTimeout(() => {
                    navigate("/login");
                }, 1000);
            } catch (e: any) {
                if (e.response?.status === 409) {
                    showError("Username or Email already exists");
                } else if (e.response?.status === 500) {
                    showError("Internal server error");
                } else {
                    showError("User registration failed");
                }
            } finally {
                setRegistrationLoading(false);
            }
        },
    });

    return (
        <Fragment>
            <Grid
                zIndex={0}
                container
                sx={containerStyle}
                alignContent={"left"}
            >
                <Grid item xs={12} md={6} lg={4.5} sx={signInFormContainer}>
                    <Typography sx={headerStyle}>SIGN UP</Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <InputLabel htmlFor="email" sx={formLabelStyle}>
                            Email
                        </InputLabel>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="off"
                            value={formik.values.email}
                            sx={registerTextInputStyle}
                            onChange={(event) => {
                                const trimValue = event.target.value.trim();
                                formik.setFieldValue("email", trimValue);
                            }}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                        />
                        <InputLabel htmlFor="username" sx={formLabelStyle}>
                            Username
                        </InputLabel>
                        <TextField
                            fullWidth
                            id="username"
                            name="username"
                            autoComplete="no-password"
                            value={formik.values.username}
                            sx={registerTextInputStyle}
                            onChange={(event) => {
                                const lowerCaseValue =
                                    event.target.value.trim();
                                formik.setFieldValue(
                                    "username",
                                    lowerCaseValue,
                                );
                            }}
                            error={
                                formik.touched.username &&
                                Boolean(formik.errors.username)
                            }
                            helperText={
                                formik.touched.username &&
                                formik.errors.username
                            }
                        />
                        <InputLabel htmlFor="password" sx={formLabelStyle}>
                            Password
                        </InputLabel>
                        <PasswordField
                            form={formik}
                            sx={registerTextInputStyle}
                            formValueName="password"
                        />
                        <InputLabel
                            htmlFor="confirmPassword"
                            sx={formLabelStyle}
                        >
                            Re-enter Password
                        </InputLabel>
                        <PasswordField
                            form={formik}
                            sx={registerTextInputStyle}
                            formValueName="confirmPassword"
                        />
                        <Button
                            type="submit"
                            sx={formSubmitButtonStyle}
                            disabled={registrationLoading}
                            endIcon={
                                <SpinnerLoader
                                    loading={registrationLoading}
                                    size={20}
                                    sx={{ marginLeft: "10px" }}
                                />
                            }
                        >
                            Sign Up
                        </Button>
                    </form>
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        marginTop={"20px"}
                    >
                        <Typography
                            sx={{ ...formLabelStyle, marginRight: "10px" }}
                        >
                            Already have an account?
                        </Typography>
                        <Typography
                            sx={{
                                ...formLabelStyle,
                                cursor: "pointer",
                                color: "#EFD016",
                            }}
                            onClick={() => navigate("/login")}
                        >
                            Sign In
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
}
export default SignUpForm;
