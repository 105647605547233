import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import PopupButton from "../common/PopupButton";
import PopupModelWrapper from "../wrappers/PopupModelWrapper";
import useBidsPopupState from "src/hooks/useBidsPopupState";
import useData from "src/hooks/useApi";
import useMessage from "src/hooks/useMessage";
import { calculateBidValueBigInt } from "src/utils/helperFunctions";
import Big from "big.js";

const UpdateModel = ({
    open,
    setOpen,
    getData,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    getData: (isReset?: boolean) => void;
}) => {
    const { postData } = useData();
    const { showError, showSuccess } = useMessage();

    const handleClose = () => {
        setPopupState((popupState) => ({
            ...popupState,
            priority: 1,
        }));
        setOpen(false);
    };
    const { setPopupState, popupState, loading, setLoading } =
        useBidsPopupState();
    const [bid, setBid] = useState<Big.Big>(new Big(0));

    useEffect(() => {
        if (popupState.regFee && popupState.successFee)
            setBid(
                calculateBidValueBigInt(
                    popupState.priority ?? 1,
                    popupState.successFee,
                    popupState.regFee,
                ),
            );
        else setBid(new Big(0));
    }, [popupState.tld, popupState.priority]);

    async function handleUpdate() {
        setLoading(true);
        try {
            if (popupState.domain) {
                const response: any = await postData("/bid/updateBid", {
                    domainName: popupState.domain,
                    priority: popupState.priority,
                });
                if (response && response.success) {
                    showSuccess(
                        `The bid priority for ${popupState.domain} domain updated successfully`,
                    );
                    getData();
                } else {
                    throw new Error("Update failed");
                }
            }
        } catch (error: any) {
            showError(`Priority update for ${popupState.domain} domain failed`);
        }
        setLoading(false);
        setOpen(false);
    }

    return (
        <PopupModelWrapper
            handleClose={handleClose}
            open={open}
            title="Update Bid"
        >
            <Stack sx={{ mx: 2.5, mt: 2, mb: 1 }} spacing={1}>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: "17px",
                        pb: 1,
                        color: "#3A3541CC",
                    }}
                >
                    Confirm your Bid for {popupState.domain} Domain
                </Typography>
                <Stack direction="row" alignItems="center">
                    <Typography
                        width={80}
                        sx={{ fontSize: "14px", color: "#3A354180" }}
                    >
                        Priority
                    </Typography>
                    <Select
                        id="demo-simple-select"
                        value={popupState.priority}
                        onChange={(e) => {
                            // @ts-expect-error This is handled from the dropdown
                            setPopupState((popupState) => ({
                                ...popupState,
                                priority: e.target.value ? +e.target.value : 1,
                            }));
                        }}
                        sx={{
                            fontSize: "14px",
                            padding: 0,
                            outlineColor: "white",
                            width: "95px",
                            textAlign: "center",
                            transition: "all 0.3s",
                            "& .MuiOutlinedInput-input": {
                                py: "5px",
                            },
                        }}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Typography
                        sx={{ fontSize: "14px", color: "#3A354180" }}
                        width={80}
                    >
                        Bid
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            textAlign: "right",
                            width: "85px",
                            p: "5px",
                            borderRadius: 1,
                            bgcolor: "#E6E6E6",
                            color: "#3A354180",
                        }}
                    >
                        {bid.toFixed(2)}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
                <PopupButton triggerFunction={handleClose} type="cancel">
                    Cancel
                </PopupButton>
                <PopupButton
                    triggerFunction={handleUpdate}
                    type="proceed"
                    disabled={loading}
                >
                    Proceed
                </PopupButton>
            </Stack>
        </PopupModelWrapper>
    );
};

export default UpdateModel;
