import { Box, Grid } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "src/components/common/DateRangePicker";
import SearchField from "src/components/common/SearchField";
import { containerStyle } from "./PostBidsPageStyle";
import {
    AuctionTableData,
    OrderPropsType,
    data,
} from "src/types/postAuctionTypes";
import { OrderTypes } from "src/enums/sortingsEnum";
import { PaginationPropsType } from "src/types/paginationTypes";
import TablePagination from "src/components/table/TablePagination";
import { calcPaginationProps } from "src/utils/helperFunctions";
import useMessage from "src/hooks/useMessage";
import { payloadType } from "src/types/dataFetchingTypes";
import useData from "src/hooks/useApi";
import UpdateModel from "../../components/postAuction/UpdateModel";
import MultiDropDown from "../../components/common/MultiDropDown";
import { myAuctionsStatusSelections } from "../../data/bidsStatusFilter";
import MyAuctionsPageTable from "../../components/postAuction/MyAuctionsPageTable";
import {
    AuctionTypeEnum,
    PostAuctionEnum,
    ProgressStatusEnum,
    UserStatusEnum,
} from "../../enums/allBidsPageEnums";
import BidTopUp from "../../components/balance/BidTopUp";
import { io } from "socket.io-client";
import { getToken, TokenTypeEnum } from "src/utils/jwtFunctions";
import {
    datePickerItem,
    filterItem,
    multipleSelectItem,
    searchItem,
} from "../preBids/PreBidsStyle";
import useNotification from "src/hooks/useNotification";

const socketUrl = process.env.REACT_APP_BACKEND_URL;

const MyAuctionsPage = ({ currentTab }: { currentTab: string }) => {
    const { showError } = useMessage();
    const { postData } = useData();
    const lastBalanceUpdateTime = useNotification();

    // JWT Token for Socket Authorization
    const token = getToken(TokenTypeEnum.AUTH);

    // top-up State
    const [topUpPopUp, setTopUpPopUp] = useState<boolean>(false);
    const [defaultAmount, setDefaultAmount] = useState<number>(20);
    const [message, setMessage] = useState<string>("");

    // Filters and searches
    const [endDate, setEndDate] = useState<DateTime>();
    const [startDate, setStartDate] = useState<DateTime>();
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    // common
    const [paginationProps, setPaginationProps] = useState<PaginationPropsType>(
        {
            pages: 0,
            rowsPerPage: 10,
            currentPage: 1,
        },
    );
    const [tableData, setTableData] = useState<data[]>([]);
    const [orderProps, setOrderProps] = useState<OrderPropsType>({
        order: OrderTypes.DESC,
        orderBy: "auctionEnd",
    });
    const [selectedRow, setSelectedRow] = useState<AuctionTableData>();
    const [loading, setLoading] = useState<boolean>(false);

    // Memory for WS Update
    const [shouldFetchData, setShouldFetchData] = useState<number>();
    const idsRef = useRef<number[]>([]);

    async function getData(isReset: boolean = false) {
        try {
            setLoading(true);
            const payload: payloadType = {
                page: paginationProps.currentPage,
                size: paginationProps.rowsPerPage,
                filterAnd: [
                    {
                        key: "auctionType",
                        operation: "IN",
                        value: [AuctionTypeEnum.POST],
                    },
                    {
                        key: "status",
                        operation: "IN",
                        value: [
                            ProgressStatusEnum.WAITING,
                            ProgressStatusEnum.INPROGRESS,
                            ProgressStatusEnum.PENDING,
                        ],
                    },
                ],
                filterOr: [],
                sort: {
                    key: orderProps.orderBy,
                    order: orderProps.order.toUpperCase(),
                },
                pageType: "Auction",
            };
            if (isReset) {
                payload.page = 1;
                setPaginationProps((paginationProps) => ({
                    ...paginationProps,
                    currentPage: 1,
                }));
            }

            if (selectedStatus.length > 0) {
                if (payload.filterAnd) {
                    const statusSet = new Set();
                    if (selectedStatus.includes(PostAuctionEnum.PAYMENT)) {
                        statusSet.add(UserStatusEnum.PENDING);
                    }
                    if (selectedStatus.includes(PostAuctionEnum.INPROGRESS)) {
                        statusSet.add(ProgressStatusEnum.WAITING);
                        statusSet.add(ProgressStatusEnum.INPROGRESS);
                    }
                    const statuses = Array.from(statusSet);
                    if (statuses.length > 0) {
                        payload.filterAnd.push({
                            // eslint-disable-next-line quotes
                            key: "status",
                            operation: "IN",
                            value: statuses,
                        });
                    }
                }
            }

            if (searchValue.trim().length > 0 && payload.filterAnd) {
                payload.filterAnd.push({
                    key: "domainName",
                    operation: "LIKE",
                    value: [`${searchValue.trim()}%`],
                });
            }

            if (startDate && payload.filterAnd) {
                payload.filterAnd.push({
                    key: "auctionEnd",
                    operation: ">",
                    value: Math.round(startDate.toSeconds()),
                });
            }

            if (endDate && payload.filterAnd) {
                payload.filterAnd.push({
                    key: "auctionEnd",
                    operation: "<",
                    value: Math.round(endDate.toSeconds()),
                });
            }

            const response: any = await postData("/bid/getUserBids", payload);

            if (response.data) {
                setTableData(response.data);
            } else {
                setTableData([]);
            }
            setPaginationProps((paginationProps) =>
                calcPaginationProps(paginationProps, response.count),
            );
        } catch (error) {
            setTableData([]);
            showError("Data fetching error");
        } finally {
            setLoading(false);
        }
    }

    const handleChangeSearch = (e: any) => {
        setSearchValue(e.target.value.trim().toLowerCase());
    };

    function bidTopUpAction(message: string, defaultAmount: number) {
        setDefaultAmount(defaultAmount);
        setMessage(message);
        setTopUpPopUp(true);
    }

    // Websocket Connection
    useEffect(() => {
        const socket = io(`${socketUrl}?token=${token}`);

        socket.on("connect", () => {
            console.log("WebSocket connected");
        });

        socket.on("bid_updated", (data: any) => {
            console.log("Web Socket Message Received with Payload:", data);
            if (data?.auctionId && idsRef.current.includes(data?.auctionId)) {
                setShouldFetchData(Math.floor(Date.now() / 1000));
            }
        });

        return () => {
            console.log("Web Socket Closed for Auction");
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        getData();
    }, [
        paginationProps.currentPage,
        orderProps.orderBy,
        orderProps.order,
        shouldFetchData,
        lastBalanceUpdateTime,
    ]);

    useEffect(() => {
        getData(true);
    }, [
        endDate,
        startDate,
        paginationProps.rowsPerPage,
        currentTab,
        selectedStatus,
    ]);

    // To Clear the Filter on Search Field Clear
    useEffect(() => {
        if (!searchValue) getData(true);
    }, [searchValue]);

    useEffect(() => {
        if (tableData.length <= 0 && paginationProps.currentPage !== 1) {
            setPaginationProps((paginationProps) => ({
                ...paginationProps,
                currentPage: 1,
            }));
        }

        // Updating Auction IDs ref when Table Data Changes
        idsRef.current =
            tableData.length > 0 ? tableData.map((item) => item.auctionId) : [];
    }, [tableData]);

    return (
        <Box alignItems="center" sx={containerStyle}>
            <UpdateModel
                open={open}
                setOpen={setOpen}
                getData={getData}
                selectedRow={selectedRow}
            />
            <BidTopUp
                open={topUpPopUp}
                setOpen={setTopUpPopUp}
                message={message}
                defaultAmount={defaultAmount}
            />
            <Grid
                container
                justifyContent={"space-between"}
                paddingBottom={containerStyle.paddingTop}
                rowSpacing={2}
            >
                <Grid item {...filterItem}>
                    <Grid container rowSpacing={2.5} columnSpacing={2}>
                        <Grid item {...searchItem}>
                            <SearchField
                                iconPosition="start"
                                onChange={handleChangeSearch}
                                placeholder="Search Domain"
                                value={searchValue}
                                id="Search-button-all-bids"
                                keyPressTrigger={(e) => {
                                    if (
                                        e.code === "Enter" ||
                                        e.key === "Enter"
                                    ) {
                                        getData(true);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item {...multipleSelectItem}>
                            <MultiDropDown
                                id="all-bids-status-dropdown"
                                label="Progress Status"
                                checkIcon={false}
                                setSelectedItems={setSelectedStatus}
                                selectedItems={selectedStatus}
                                data={myAuctionsStatusSelections}
                                containerStyles={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item {...datePickerItem}>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}
                                startDateLabel={"Auction End"}
                                endDateLabel={"Auction End"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <MyAuctionsPageTable
                apiData={tableData}
                orderProps={orderProps}
                setOrderProps={setOrderProps}
                updateProps={{ open, setOpen, setSelectedRow, selectedRow }}
                loading={loading}
                popupHandlers={{
                    delete: function handledelete() {},
                    topUp: bidTopUpAction,
                }}
            />
            <TablePagination
                paginationProps={paginationProps}
                setPaginationProps={setPaginationProps}
            />
        </Box>
    );
};

export default MyAuctionsPage;
