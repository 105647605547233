import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
    contentStyle,
    listItemTextStyle,
    mainContainerStyle,
    topicStyle,
    listItemStyle,
    mainTextContainer,
    iconStyle,
    buttonStyle,
    scrollArrowStyle,
    pageHeightInTabRes,
} from "./About.style";
import ConcentricCirclesIcon from "../icons/ConcentricCirclesIcon";
import Button from "@mui/material/Button";
import { isTabResolution } from "src/utils/helperFunctions";
import { pageHeightWithoutHeader } from "../SharedStyles";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import useAuth from "src/hooks/useAuth";

function About({ smoothScroll }: { smoothScroll: () => void }) {
    const auth = useAuth();

    const [pageHeight, setPageHeight] = useState<string>();
    const [smoothScrollingVisible, setSmoothScrollingVisible] = useState(true);

    const contentRef = useRef<HTMLDivElement | null>(null);

    const checkPosition = () => {
        if (contentRef.current) {
            const rect = contentRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const componentBottom = rect.bottom;
            setSmoothScrollingVisible(componentBottom > viewportHeight * 0.8);
        }
    };

    const updateHeight = () => {
        setPageHeight(
            isTabResolution(window)
                ? pageHeightInTabRes
                : pageHeightWithoutHeader,
        );
    };

    useLayoutEffect(() => {
        updateHeight();
    }, []);

    useEffect(() => {
        checkPosition();

        window.addEventListener("resize", updateHeight);
        window.addEventListener("scroll", checkPosition);

        return () => {
            window.removeEventListener("resize", updateHeight);
            window.removeEventListener("scroll", checkPosition);
        };
    }, []);

    return (
        <Fragment>
            <Grid
                zIndex={0}
                container
                sx={{
                    ...mainContainerStyle,
                    height: pageHeight,
                    position: "relative",
                }}
                ref={contentRef}
            >
                <Grid item xs={12} sx={mainTextContainer}>
                    <Typography sx={{ ...topicStyle, marginBottom: "15px" }}>
                        BACKORDER EXPIRED
                    </Typography>
                    <Typography sx={topicStyle}>DOMAINS</Typography>
                    <Typography sx={contentStyle}>
                        Our next-generation drop catching system makes the
                        process of registering expired domain names easier than
                        ever
                    </Typography>
                    <List disablePadding>
                        <ListItem disablePadding sx={listItemStyle}>
                            <ConcentricCirclesIcon sx={iconStyle} />
                            <ListItemText>
                                <Typography sx={listItemTextStyle}>
                                    User-friendly Control Panel
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem disablePadding sx={listItemStyle}>
                            <ConcentricCirclesIcon sx={iconStyle} />
                            <ListItemText>
                                <Typography sx={listItemTextStyle}>
                                    Few Clicks
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem disablePadding sx={listItemStyle}>
                            <ConcentricCirclesIcon sx={iconStyle} />
                            <ListItemText>
                                <Typography sx={listItemTextStyle}>
                                    Low Prices
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem disablePadding sx={listItemStyle}>
                            <ConcentricCirclesIcon sx={iconStyle} />
                            <ListItemText>
                                <Typography sx={listItemTextStyle}>
                                    Every Extension
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <Button
                        sx={buttonStyle}
                        href={
                            auth.isLogged ? "/dashboard/preBids" : "/register"
                        }
                    >
                        {auth.isLogged ? "Backorder Now" : "Create Account"}
                    </Button>
                </Grid>
                {smoothScrollingVisible && (
                    <KeyboardDoubleArrowDownIcon
                        fontSize="medium"
                        sx={scrollArrowStyle}
                        onClick={smoothScroll}
                    />
                )}
            </Grid>
        </Fragment>
    );
}

export default About;
