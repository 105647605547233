import {
    Box,
    Button,
    Chip,
    Table,
    TableBody,
    TableContainer,
    TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    dateTimeConvertor,
    getCurrentTime,
    formatTimeRemaining,
} from "src/utils/helperFunctions";
import EmptyTable from "../table/EmptyTable";
import {
    AuctionTableData,
    OrderPropsType,
    data,
    postAuctionHeading,
} from "src/types/postAuctionTypes";
import PostAuctionHeadRow from "./PostAuctionHeadRow";
import LoadingTable from "../table/LoadingTable";
import ProgressStatusChip from "../common/ProgressStatusChip";
import Big from "big.js";
import {
    ProgressStatusBgColors,
    ProgressStatusTextColors,
    UserStatusEnum,
} from "../../enums/allBidsPageEnums";
import Grid from "@mui/material/Grid";
import { BsPencilSquare } from "react-icons/bs";
import MyBackordersActionMenu from "../allBids/MyBackordersActionMenu";
import useMessage from "../../hooks/useMessage";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../SharedStyles";
import { isUserCompleted } from "src/utils/jwtFunctions";

const MyAuctionsPageTable = ({
    apiData,
    orderProps,
    setOrderProps,
    updateProps,
    loading,
    popupHandlers,
}: {
    apiData: data[];
    orderProps: OrderPropsType;
    setOrderProps: Dispatch<SetStateAction<OrderPropsType>>;
    updateProps: {
        open: boolean;
        setOpen: Dispatch<SetStateAction<boolean>>;
        setSelectedRow: Dispatch<SetStateAction<AuctionTableData | undefined>>;
        selectedRow: AuctionTableData | undefined;
    };
    loading?: boolean;
    popupHandlers: {
        delete: () => void;
        topUp: (message: string, defaultAmount: number) => void;
    };
}) => {
    const [rows, setRows] = useState<AuctionTableData[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const convertedData: AuctionTableData[] = apiData.map((item) => ({
            ...item,
            amount: new Big(item.amount),
        }));
        setRows(convertedData);
    }, [apiData]);

    const { showError } = useMessage();

    async function updateBid(row: AuctionTableData) {
        if (!isUserCompleted()) {
            showError("Please complete the profile");
        } else {
            updateProps.setSelectedRow(row);
            updateProps.setOpen(true);
        }
    }

    const StyledChip = styled(Chip)(() => ({
        fontSize: "12px",
        borderRadius: 5,
        height: "25px",
    }));

    const statusCell = (bid: Big, currentPrice: number) => {
        if (bid && currentPrice && bid.gte(currentPrice)) {
            return (
                <StyledChip
                    label={"Highest"}
                    sx={{
                        color: ProgressStatusTextColors.SUCCESS,
                        bgcolor: ProgressStatusBgColors.SUCCESS,
                    }}
                />
            );
        } else {
            return (
                <StyledChip
                    label={"Outbid"}
                    sx={{
                        color: ProgressStatusTextColors.ARCHIVED,
                        bgcolor: ProgressStatusBgColors.ARCHIVED,
                    }}
                />
            );
        }
    };

    const StyledTableRow = styled(TableRow)(() => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    return (
        <TableContainer
            sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: "100%" },
                minWidth: { sm: "500px" },
            }}
        >
            <Table
                sx={{
                    width: "100%",
                    mx: "auto",
                }}
            >
                <PostAuctionHeadRow
                    order={orderProps.order}
                    orderBy={orderProps.orderBy}
                    headRow={postAuctionHeading}
                    setOrderProps={setOrderProps}
                />
                <TableBody>
                    {loading ? (
                        <LoadingTable colspan={postAuctionHeading.length} />
                    ) : apiData.length > 0 ? (
                        rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                >
                                    {
                                        <div
                                            onClick={() => {
                                                navigate(
                                                    `/auctions/${row.auctionId}`,
                                                );
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                color: "#21B2E7",
                                            }}
                                        >
                                            {row.domainName}
                                        </div>
                                    }
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.currentPrice}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    <Grid
                                        container
                                        display="flex"
                                        justifyContent={"center"}
                                        spacing={1}
                                    >
                                        <Grid item>
                                            {row.amount.toFixed(2)}
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={() => {
                                                    updateBid(row);
                                                }}
                                                sx={{
                                                    minWidth: "12px",
                                                    padding: 0,
                                                }}
                                                disabled={
                                                    Number(row.auctionEnd) <
                                                    getCurrentTime()
                                                }
                                            >
                                                <BsPencilSquare
                                                    style={{
                                                        color: "#21B2E7",
                                                        fontSize: "13px",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {statusCell(row.amount, row.currentPrice)}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <ProgressStatusChip
                                        chipType={
                                            row.status ===
                                            UserStatusEnum.PENDING
                                                ? UserStatusEnum.PAYMENT
                                                : row.status
                                        }
                                    />
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    <Tooltip
                                        title={
                                            row.auctionEnd
                                                ? `${
                                                      dateTimeConvertor(
                                                          +row.auctionEnd,
                                                      ).date
                                                  } ${
                                                      dateTimeConvertor(
                                                          +row.auctionEnd,
                                                      ).time
                                                  }`
                                                : ""
                                        }
                                        enterTouchDelay={0}
                                    >
                                        <Box>
                                            {formatTimeRemaining(
                                                Number(row.auctionEnd),
                                            )}
                                        </Box>
                                    </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.bidders}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    sx={{
                                        my: 1,
                                    }}
                                >
                                    <MyBackordersActionMenu
                                        id="RowMenu"
                                        data={row}
                                        popupHandlers={popupHandlers}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <EmptyTable
                            msg="No data"
                            colSpan={postAuctionHeading.length}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MyAuctionsPageTable;
