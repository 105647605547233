import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import InputSection from "src/components/registrantProfile/InputSection";
import InputSectionNameServers from "src/components/registrantProfile/InputSectionDomain";
import {
    regProfileStyles,
    checkboxContainerStyle,
} from "src/components/registrantProfile/registrantProfileStyles";
import useData from "src/hooks/useApi";
import useAuth from "src/hooks/useAuth";
import {
    defaultValues,
    FormValuesType,
    generateSchema,
} from "src/components/registrantProfile/validations";
import PageLoader from "../components/common/PageLoader";
import useMessage from "../hooks/useMessage";
import {
    mainContentContainer,
    pageHeaderStyle,
} from "src/components/SharedStyles";
import CheckBoxWithLabel from "src/components/common/CheckBox";
import HeaderWithSeparator from "src/components/common/HeaderWithSeperator";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
    FormDataType,
    InputSectionTag,
    ToastMessages,
} from "src/types/registrantProfileTypes";

const RegistrantProfilePageForm = ({ isUpdate }: { isUpdate: boolean }) => {
    const { postData, fetchData } = useData();
    const { showSuccess, showError } = useMessage();
    const navigate = useNavigate();
    const auth = useAuth();
    // Loading State for BE update
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleFormSubmit = async (values: FormValuesType) => {
        try {
            setIsUpdating(true);
            const {
                ownerInfo,
                adminSameAsOwner,
                technicalSameAsOwner,
                billingSameAsOwner,
                nameservers,
                allowDailyEmailReports,
            } = values;

            const formData: FormDataType = {
                ownerInfo: ownerInfo,
                adminInfo: adminSameAsOwner
                    ? { ...ownerInfo, sameAsOwner: adminSameAsOwner }
                    : { ...values.adminInfo, sameAsOwner: adminSameAsOwner },
                technicalContact: technicalSameAsOwner
                    ? { ...ownerInfo, sameAsOwner: technicalSameAsOwner }
                    : {
                          ...values.technicalInfo,
                          sameAsOwner: technicalSameAsOwner,
                      },
                billingContact: billingSameAsOwner
                    ? { ...ownerInfo, sameAsOwner: billingSameAsOwner }
                    : {
                          ...values.billingInfo,
                          sameAsOwner: billingSameAsOwner,
                      },
                nameserverSettings: {
                    nameserver1: nameservers.nameserver1,
                    nameserver2: nameservers.nameserver2,
                },
                allowDailyEmailReports: allowDailyEmailReports,
            };

            // used for CREATE and UPDATE
            const result: any = await postData(
                "/users/createRegistrantProfile",
                formData,
            );

            if (result.success) {
                setIsUpdateSuccess(true);
            } else {
                showError(ToastMessages.UPDATE_FAILED);
            }
        } catch (error) {
            console.log(error);
            showError(ToastMessages.UPDATE_FAILED);
        } finally {
            setIsUpdating(false);
        }
    };

    useEffect(() => {
        if (isUpdateSuccess) {
            showSuccess(ToastMessages.UPDATE_SUCCESS);
            if (!isUpdate) {
                auth.switchIsCompleted(true);
            }
            setTimeout(() => {
                setIsUpdating(false);
                navigate("/dashboard/preBids");
            }, 1200);
        }
    }, [isUpdateSuccess]);

    const [isOwnerAdmin, setIsOwnerAdmin] = useState<boolean>(true);
    const [isOwnerTechnical, setIsOwnerTechnical] = useState<boolean>(true);
    const [isOwnerBilling, setIsOwnerBilling] = useState<boolean>(true);

    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: generateSchema({
            adminSameAsOwner: isOwnerAdmin,
            billingSameAsOwner: isOwnerBilling,
            technicalSameAsOwner: isOwnerTechnical,
        }),
        onSubmit: handleFormSubmit,
    });

    const setAdminSameAsOwner = (isSame: boolean) => {
        setFormValue("adminSameAsOwner", isSame);
        setIsOwnerAdmin(isSame);
    };

    const setTechnicalSameAsOwner = (isSame: boolean) => {
        setFormValue("technicalSameAsOwner", isSame);
        setIsOwnerTechnical(isSame);
    };

    const setBillingSameAsOwner = (isSame: boolean) => {
        setFormValue("billingSameAsOwner", isSame);
        setIsOwnerBilling(isSame);
    };

    function setFormValue(field: string, value: any) {
        formik.setFieldValue(field, value);
    }

    async function loadData() {
        try {
            setLoading(true);
            if (isUpdate) {
                const response: FormDataType = await fetchData(
                    "/users/getRegistrantProfileDetails",
                );
                if (response) {
                    // Set Owner Info
                    setFormValue(
                        InputSectionTag.OWNER_INFO,
                        response.ownerInfo,
                    );

                    // Set Admin Info if available
                    if (response.adminInfo) {
                        setAdminSameAsOwner(response.adminInfo.sameAsOwner);
                        setFormValue(
                            InputSectionTag.ADMIN_INFO,
                            response.adminInfo,
                        );
                    }

                    // Set Technical Info if available
                    if (response.technicalContact) {
                        setTechnicalSameAsOwner(
                            response.technicalContact.sameAsOwner,
                        );
                        setFormValue(
                            InputSectionTag.TECHNICAL_INFO,
                            response.technicalContact,
                        );
                    }

                    // Set Billing Info if available
                    if (response.billingContact) {
                        setBillingSameAsOwner(
                            response.billingContact.sameAsOwner,
                        );
                        setFormValue(
                            InputSectionTag.BILLING_INFO,
                            response.billingContact,
                        );
                    }

                    // Set Nameserver info
                    setFormValue(
                        InputSectionTag.NAMESERVERS,
                        response.nameserverSettings,
                    );
                    setFormValue(
                        "allowDailyEmailReports",
                        response.allowDailyEmailReports,
                    );
                } else {
                    showError(ToastMessages.COMPLETE_PROFILE);
                }
            } else {
                // set empty owner info, i.e. set email of user
                setFormValue("ownerInfo.email", auth.user?.email ?? "");
            }
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        }
    }

    useEffect(() => {
        loadData();
    }, [auth.user, isUpdate]);

    useEffect(() => {
        if (isUpdateSuccess) {
            showSuccess(ToastMessages.UPDATE_SUCCESS);
            if (!isUpdate) {
                auth.switchIsCompleted(true);
            }
            setTimeout(() => {
                setIsUpdating(false);
                navigate("/dashboard/preBids");
            }, 1200);
        }
    }, [isUpdateSuccess]);

    const getEmailReportsFlag = () => formik.values.allowDailyEmailReports;
    const toggleEmailReportsFlag = () => {
        setFormValue(
            "allowDailyEmailReports",
            !formik.values.allowDailyEmailReports,
        );
    };

    // Passed onto the form onSubmit. Handles showing the error message toast if form data is invalid
    const formOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        const isFormTouched = Object.keys(formik.touched).length != 0;
        if (isFormTouched && !formik.isValid)
            showError(ToastMessages.UPDATE_FAILED);
        formik.handleSubmit(e);
    };

    return (
        <Box alignItems="center" sx={mainContentContainer}>
            {loading && isUpdate ? (
                <PageLoader height="70vh" />
            ) : (
                <form onSubmit={formOnSubmit}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={pageHeaderStyle} align={"left"}>
                                Profile Settings
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <HeaderWithSeparator
                                label="Owner Info"
                                labelStyle={regProfileStyles.sectionHeading}
                            />
                            <InputSection
                                tag={InputSectionTag.OWNER_INFO}
                                disabled={isUpdating}
                                formik={formik}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HeaderWithSeparator
                                label="Admin Info"
                                labelStyle={regProfileStyles.sectionHeading}
                            />
                            <CheckBoxWithLabel
                                label={"Same as owner"}
                                isChecked={isOwnerAdmin}
                                setIsChecked={setAdminSameAsOwner}
                                disabled={isUpdating}
                                sx={checkboxContainerStyle}
                            />
                            {!isOwnerAdmin && (
                                <InputSection
                                    tag={InputSectionTag.ADMIN_INFO}
                                    disabled={isUpdating}
                                    formik={formik}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <HeaderWithSeparator
                                label="Technical Info"
                                labelStyle={regProfileStyles.sectionHeading}
                            />
                            <CheckBoxWithLabel
                                label={"Same as owner"}
                                isChecked={isOwnerTechnical}
                                setIsChecked={setTechnicalSameAsOwner}
                                disabled={isUpdating}
                                sx={checkboxContainerStyle}
                            />
                            {!isOwnerTechnical && (
                                <InputSection
                                    tag={InputSectionTag.TECHNICAL_INFO}
                                    disabled={isUpdating}
                                    formik={formik}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <HeaderWithSeparator
                                label="Billing Contact"
                                labelStyle={regProfileStyles.sectionHeading}
                            />
                            <CheckBoxWithLabel
                                label={"Same as owner"}
                                isChecked={isOwnerBilling}
                                setIsChecked={setBillingSameAsOwner}
                                disabled={isUpdating}
                                sx={checkboxContainerStyle}
                            />
                            {!isOwnerBilling && (
                                <InputSection
                                    tag={InputSectionTag.BILLING_INFO}
                                    disabled={isUpdating}
                                    formik={formik}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <HeaderWithSeparator
                                label="Nameserver Settings"
                                labelStyle={regProfileStyles.sectionHeading}
                            />
                            <InputSectionNameServers
                                disabled={isUpdating}
                                formik={formik}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <HeaderWithSeparator
                                label="Daily Statistics Report"
                                labelStyle={regProfileStyles.sectionHeading}
                            />
                            <Grid
                                container
                                component={"span"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                flexWrap={"nowrap"}
                                sx={{
                                    paddingTop: 2,
                                }}
                                spacing={{ xs: 1, sm: 3 }}
                            >
                                <Grid item>
                                    <Typography color={"#3A3541CC"}>
                                        {"Send me Daily Statistic Report"}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Checkbox
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: 28,
                                                margin: "0 !important",
                                                padding: 0,
                                            },
                                            margin: 0,
                                            padding: 0,
                                        }}
                                        name={"allowDailyEmailReports"}
                                        checked={getEmailReportsFlag()}
                                        onChange={() =>
                                            toggleEmailReportsFlag()
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            <Button
                                type={"submit"}
                                sx={regProfileStyles.buttonStyle}
                                disabled={isUpdating}
                            >
                                {isUpdate ? "Update" : "Submit"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Box>
    );
};

export default RegistrantProfilePageForm;
