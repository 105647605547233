import { Box, Grid } from "@mui/material";
import { DateTime } from "luxon";
import { lazy, Suspense, useEffect, useState } from "react";
import DateRangePicker from "src/components/common/DateRangePicker";
import SearchField from "src/components/common/SearchField";
import { OrderPropsType, data } from "src/types/postAuctionTypes";
import { OrderTypes } from "src/enums/sortingsEnum";
import { PaginationPropsType } from "src/types/paginationTypes";
import TablePagination from "src/components/table/TablePagination";
import { calcPaginationProps } from "src/utils/helperFunctions";
import useMessage from "src/hooks/useMessage";
import { payloadType } from "src/types/dataFetchingTypes";
import useData from "src/hooks/useApi";
import MultiDropDown from "../../components/common/MultiDropDown";
import { resultsStatusSelections } from "../../data/bidsStatusFilter";
import {
    ProgressStatusEnum,
    UserStatusEnum,
} from "../../enums/allBidsPageEnums";
import {
    buttonItem,
    containerStyle,
    datePickerItem,
    filterItem,
    multipleSelectItem,
    searchItem,
} from "../preBids/PreBidsStyle";
import ResultsTable from "../../components/table/ResultsTable";
import useNotification from "src/hooks/useNotification";
import SpinnerLoader from "src/components/loader/SpinnerLoader";

const ResultsExportButton = lazy(
    () => import("src/components/allBids/ResultsExportButton"),
);

const ResultsExportBtnFallback = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: { xs: "100%", sm: "120px" },
                height: "45px",
            }}
        >
            <SpinnerLoader loading={true} size={20} sx={undefined} />
        </Box>
    );
};

const ResultsPage = ({ currentTab }: { currentTab: string }) => {
    const { showError } = useMessage();
    const { postData } = useData();
    const lastBalanceUpdateTime = useNotification();

    // Filters and searches
    const [endDate, setEndDate] = useState<DateTime>();
    const [startDate, setStartDate] = useState<DateTime>();
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

    // common
    const [paginationProps, setPaginationProps] = useState<PaginationPropsType>(
        {
            pages: 0,
            rowsPerPage: 10,
            currentPage: 1,
        },
    );
    const [tableData, setTableData] = useState<data[]>([]);
    const [orderProps, setOrderProps] = useState<OrderPropsType>({
        order: OrderTypes.DESC,
        orderBy: "auctionEnd",
    });
    const [loading, setLoading] = useState<boolean>(false);

    async function getData(
        isReset: boolean = false,
        exportFn?: (data: data[]) => void,
    ) {
        const isExport = !!exportFn;
        try {
            if (!isExport) setLoading(true);
            const payload: payloadType = {
                page: isExport ? 1 : paginationProps.currentPage,
                size: isExport
                    ? paginationProps.pages * paginationProps.rowsPerPage
                    : paginationProps.rowsPerPage,
                filterAnd: [
                    {
                        key: "status",
                        operation: "IN",
                        value:
                            selectedStatus.length === 0
                                ? [
                                      ProgressStatusEnum.SUCCESS,
                                      ProgressStatusEnum.FAILURE,
                                      UserStatusEnum.ARCHIVED,
                                      UserStatusEnum.AUCTION_LOST,
                                  ]
                                : selectedStatus,
                    },
                ],
                filterOr: [],
                sort: {
                    key: orderProps.orderBy,
                    order: orderProps.order.toUpperCase(),
                },
            };

            if (isReset) {
                payload.page = 1;
                setPaginationProps((paginationProps) => ({
                    ...paginationProps,
                    currentPage: 1,
                }));
            }

            if (searchValue.trim().length > 0 && payload.filterAnd) {
                payload.filterAnd.push({
                    key: "domainName",
                    operation: "LIKE",
                    value: [`${searchValue.trim()}%`],
                });
            }

            if (startDate && payload.filterAnd) {
                payload.filterAnd.push({
                    key: "auctionEnd",
                    operation: ">",
                    value: Math.round(startDate.toSeconds()),
                });
            }

            if (endDate && payload.filterAnd) {
                payload.filterAnd.push({
                    key: "auctionEnd",
                    operation: "<",
                    value: Math.round(endDate.toSeconds()),
                });
            }

            const response: any = await postData("/bid/getUserBids", payload);

            if (isExport) {
                if (response.data) {
                    exportFn(response.data);
                }
            } else {
                if (response.data) {
                    setTableData(response.data);
                } else {
                    setTableData([]);
                }
                setPaginationProps((paginationProps) =>
                    calcPaginationProps(paginationProps, response.count),
                );
            }
        } catch (error) {
            setTableData([]);
            showError("Data fetching error");
        } finally {
            if (!isExport) setLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, [
        paginationProps.currentPage,
        orderProps.orderBy,
        orderProps.order,
        lastBalanceUpdateTime,
    ]);

    useEffect(() => {
        getData(true);
    }, [
        endDate,
        startDate,
        paginationProps.rowsPerPage,
        currentTab,
        selectedStatus,
    ]);

    // To Clear the Filter on Search Field Clear
    useEffect(() => {
        if (!searchValue) getData(true);
    }, [searchValue]);

    useEffect(() => {
        if (tableData.length <= 0 && paginationProps.currentPage !== 1) {
            setPaginationProps((paginationProps) => ({
                ...paginationProps,
                currentPage: 1,
            }));
        }
    }, [tableData]);

    const handleChangeSearch = (e: any) => {
        setSearchValue(e.target.value.trim().toLowerCase());
    };

    return (
        <Box alignItems="center" sx={containerStyle}>
            <Grid
                container
                justifyContent={"space-between"}
                paddingBottom={containerStyle.paddingTop}
                rowSpacing={2}
            >
                <Grid item {...filterItem}>
                    <Grid container rowSpacing={2.5} columnSpacing={2}>
                        <Grid item {...searchItem}>
                            <SearchField
                                iconPosition="start"
                                onChange={handleChangeSearch}
                                placeholder="Search Domain"
                                value={searchValue}
                                id="Search-button-all-bids"
                                keyPressTrigger={(e) => {
                                    if (
                                        e.code === "Enter" ||
                                        e.key === "Enter"
                                    ) {
                                        getData(true);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item {...multipleSelectItem}>
                            <MultiDropDown
                                id="all-bids-status-dropdown"
                                label="Progress Status"
                                checkIcon={false}
                                setSelectedItems={setSelectedStatus}
                                selectedItems={selectedStatus}
                                data={resultsStatusSelections}
                                containerStyles={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item {...datePickerItem}>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}
                                startDateLabel={"Auction End"}
                                endDateLabel={"Auction End"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item {...buttonItem}>
                    <Suspense fallback={<ResultsExportBtnFallback />}>
                        <ResultsExportButton
                            getExportData={(exportFn: (data: data[]) => void) =>
                                getData(false, exportFn)
                            }
                        />
                    </Suspense>
                </Grid>
            </Grid>
            <ResultsTable
                apiData={tableData}
                orderProps={orderProps}
                setOrderProps={setOrderProps}
                loading={loading}
            />
            <TablePagination
                paginationProps={paginationProps}
                setPaginationProps={setPaginationProps}
            />
        </Box>
    );
};

export default ResultsPage;
