export const bulletStyle = {
    fontSize: "8px",
    color: "#FFB700",
    marginTop: "8px",
};

export const TypographyContentStyle = {
    fontSize: "16px",
    lineHeight: "16px",
    color: "#615d67",
    "& > *": {
        whiteSpace: "normal !important",
    },
};

export const TypographyHeaderStyle = {
    fontSize: "25px",
    fontWeight: 600,
    color: "#FFB700",
    marginBottom: 1.5,
};

export const NotificationBoxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    bgcolor: "white",
    color: "black",
    padding: 3,
    borderRadius: 3,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "70vw", md: "35vw" },
    maxHeight: { xs: "50dvh", md: "35dvh" },
    outline: "none",
};

export const closeIconStyle = {
    position: "absolute",
    top: 15,
    right: 15,
    cursor: "pointer",
    color: "#615d67",
};
