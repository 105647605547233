import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
    HeadingType,
    data,
    OrderPropsType,
    TableData,
} from "../../types/messagesPageTypes";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { dateTimeConvertor, upperFirstLetter } from "src/utils/helperFunctions";
import EmptyTable from "../table/EmptyTable";
import MessagesHeadRow from "./MessagesHeadRow";
import LevelChip from "../common/LevelChip";
import CollapsibleCell from "../table/CollapsibleCell";
import useData from "src/hooks/useApi";
import LoadingTable from "../table/LoadingTable";
import { StyledTableCell } from "../SharedStyles";

const headRow: HeadingType[] = [
    {
        id: "message",
        label: "Message",
        align: "left",
    },
    {
        id: "level",
        label: "Level",
    },
    {
        id: "sentAt",
        label: "Received at",
        sorting: true,
    },
];

const MessagesPageTable = ({
    apiData,
    orderProps,
    setOrderProps,
    loading,
}: {
    apiData: data[];
    orderProps: OrderPropsType;
    setOrderProps: Dispatch<SetStateAction<OrderPropsType>>;
    loading: boolean;
}) => {
    const [rows, setRows] = useState<TableData[]>([]);
    const api = useData();

    useEffect(() => {
        const convertedData: TableData[] = apiData.map((item) => ({
            ...item,
            receivedAt: `${dateTimeConvertor(item.sentAt).date} ${
                dateTimeConvertor(item.sentAt).time
            }`,
        }));
        setRows(convertedData);
    }, [apiData]);

    const StyledTableRow = styled(TableRow)(() => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const readTheMessage = async (row: TableData) => {
        if (!row.viewedAt) {
            try {
                await api.postData("/messages/updateMessageViewedAtTime", {
                    ids: [row.id],
                });
            } catch (error) {
                console.log("Couldn't read the message");
            }
        }
    };

    return (
        <TableContainer
            sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: "100%" },
                minWidth: { sm: "500px" },
            }}
        >
            <Table
                sx={{
                    width: "100%",
                    mx: "auto",
                }}
            >
                <MessagesHeadRow
                    order={orderProps.order}
                    orderBy={orderProps.orderBy}
                    headRow={headRow}
                    setOrderProps={setOrderProps}
                />
                <TableBody>
                    {loading ? (
                        <LoadingTable colspan={headRow.length} />
                    ) : apiData.length > 0 ? (
                        rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    sx={{
                                        width: {
                                            sm: "500px",
                                            md: "600px",
                                            lg: "800px",
                                        },
                                    }}
                                    onClick={() => readTheMessage(row)}
                                >
                                    {row.message.length > 100 ? (
                                        <CollapsibleCell
                                            text={upperFirstLetter(row.message)}
                                            length={100}
                                        />
                                    ) : (
                                        upperFirstLetter(row.message)
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <LevelChip type={row.level} />
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.receivedAt}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <EmptyTable msg="No messages" colSpan={4} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MessagesPageTable;
