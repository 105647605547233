import { useState, useEffect, Suspense, lazy } from "react";
import {
    Box,
    Button,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import useData from "src/hooks/useApi";
import useBalance from "src/hooks/useBalance";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { headerContentStyle } from "./navigation/LandingHeader.style";
import { dataStripHeight } from "./SharedStyles";
import useNotification from "src/hooks/useNotification";

const LazyTopUpPopUp = lazy(() => import("./balance/TopUpPopup"));

export const topUpButton = {
    py: "3px",
    px: "8px",
    bgcolor: "black",
    color: "#EFD016",
    fontSize: { xs: "9px", sm: "12px" },
    fontWeight: 500,
    borderRadius: 20,
    textTransform: "none",
    "&:hover": {
        bgcolor: "black",
    },
    marginLeft: "10px",
    minWidth: "80px",
    boxShadow: "0",
};

function ClockTypography() {
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

    const [time, setTime] = useState<Date>(new Date());

    const tick = () => {
        setTime(new Date());
    };

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);

        return function cleanup() {
            clearInterval(timerID);
        };
    }, []);

    return (
        <Typography
            sx={{
                color: "#3A3541",
                opacity: 0.8,
                fontSize: {
                    xs: "10px",
                    sm: "11px",
                    md: "14px",
                },
                fontWeight: 500,
            }}
        >
            {time.toUTCString().slice(isDownSm ? 4 : 0, 25)} {"UTC"}
        </Typography>
    );
}

export default function FixedDataStrip() {
    const api = useData();
    const balance = useBalance();
    const lastBalanceUpdateTime = useNotification();

    const [topUpPopUp, setTopUpPopUp] = useState<boolean>(false);

    const fontSizeMedium: string = "14px";
    const fontSizeSmall: string = "11px";
    const fontSizeXSmall: string = "10px";

    async function loadCurrentBalance() {
        const payload = {
            page: 1,
            size: 1,
        };
        try {
            const response: any = await api?.postData(
                "/transaction/getUserTransactionData",
                payload,
            );
            if (response != null) {
                balance?.setBalance(
                    response.currentBalance ? response.currentBalance : "0",
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadCurrentBalance();
    }, [lastBalanceUpdateTime]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                bgcolor: "#D8DEE8",
                height: dataStripHeight,
                justifyContent: "space-between",
                px: headerContentStyle.paddingX,
                flexDirection: "row",
                mt: 0,
            }}
        >
            <Suspense>
                <LazyTopUpPopUp
                    open={topUpPopUp}
                    setOpen={setTopUpPopUp}
                    message={"Select your Top-up amount"}
                />
            </Suspense>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <AccessTimeIcon
                    fontSize="small"
                    style={{
                        color: "#3A3541",
                        opacity: "80%",
                        paddingRight: "10px",
                    }}
                ></AccessTimeIcon>
                <ClockTypography />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <AccountBalanceIcon
                    fontSize="small"
                    sx={{ px: { xs: 0.5, sm: 1, md: 1 } }}
                    style={{
                        color: "#3A3541",
                        opacity: "80%",
                    }}
                ></AccountBalanceIcon>
                <Typography
                    sx={{
                        marginRight: "0.2rem",
                        color: "#3A3541",
                        opacity: 0.8,
                        fontSize: {
                            xs: "0px",
                            sm: fontSizeSmall,
                            md: fontSizeMedium,
                        },
                        fontWeight: 500,
                    }}
                >
                    Current Balance :
                </Typography>
                <Typography
                    sx={{
                        color: "#3BB800",
                        fontSize: {
                            xs: fontSizeXSmall,
                            sm: fontSizeSmall,
                            md: fontSizeMedium,
                        },
                        fontWeight: 500,
                    }}
                >
                    {balance.currentBalance
                        ? balance.currentBalance.toFixed(2)
                        : "0.00"}
                </Typography>
                <Typography
                    sx={{
                        color: "#3A3541",
                        opacity: 0.8,
                        marginLeft: "0.2rem",
                        fontSize: {
                            xs: fontSizeXSmall,
                            sm: fontSizeSmall,
                            md: fontSizeMedium,
                        },
                        fontWeight: 500,
                    }}
                >
                    USD
                </Typography>
                <Button
                    variant="contained"
                    sx={topUpButton}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => {
                        setTopUpPopUp(true);
                    }}
                >
                    Top-up
                </Button>
            </Box>
        </Box>
    );
}
