import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import InputFieldWrapper from "src/components/registrantProfile/InputFieldWrapper";
import { regProfileStyles } from "./registrantProfileStyles";
import useFormikField from "../../hooks/useFormikField";
import { InputSectionTag } from "../../types/registrantProfileTypes";
import { getValue, trimSpaces } from "src/utils/helperFunctions";

const InputSection = ({
    disabled = false,
    formik,
}: {
    disabled?: boolean;
    formik: any;
}) => {
    const { getFieldName, getFieldValue, fieldHasErrors, getValidationError } =
        useFormikField(formik, InputSectionTag.NAMESERVERS);

    const renderNSInput = ({
        fieldName,
        label,
        inputDisabled = disabled,
    }: {
        fieldName: string;
        label: string;
        inputDisabled?: boolean;
    }) => {
        return (
            <InputFieldWrapper
                hasWarning={fieldHasErrors(fieldName)}
                label={label}
                warning={getValidationError(fieldName)}
            >
                <TextField
                    id={getFieldName(fieldName)}
                    name={getFieldName(fieldName)}
                    type="text"
                    onChange={(e) => {
                        const value = trimSpaces(getValue(e.target.value));
                        formik.setFieldValue(getFieldName(fieldName), value);
                    }}
                    value={getFieldValue(fieldName)}
                    sx={regProfileStyles.textField}
                    inputProps={{
                        style: {
                            margin: 0,
                            padding: "10px 8px",
                        },
                    }}
                    disabled={inputDisabled}
                />
            </InputFieldWrapper>
        );
    };

    return (
        <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            paddingTop={2}
            paddingBottom={2}
        >
            <Grid sm={6} xs={12}>
                {renderNSInput({
                    fieldName: "nameserver1",
                    label: "Nameserver 1",
                })}
            </Grid>
            <Grid sm={6} xs={12}>
                {renderNSInput({
                    fieldName: "nameserver2",
                    label: "Nameserver 2",
                })}
            </Grid>
        </Grid>
    );
};

export default InputSection;
