import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
    HeadingType,
    data,
    OrderPropsType,
} from "../../types/balancePageTypes";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import BalanceHeadRow from "../table/BalanceHeadRow";
import EmptyTable from "../table/EmptyTable";
import LoadingTable from "../table/LoadingTable";
import { StyledTableCell } from "../SharedStyles";

const headRow: HeadingType[] = [
    {
        id: "id",
        label: "Order ID",
        align: "left",
    },
    {
        id: "created_at",
        label: "Date",
    },
    {
        id: "msg",
        label: "Message",
        align: "left",
    },
    {
        id: "difference",
        label: "Amount ($)",
    },
];

const BalancePageTable = ({
    apiData,
    orderProps,
    setOrderProps,
    loading,
}: {
    apiData: data[];
    orderProps: OrderPropsType;
    setOrderProps: Dispatch<SetStateAction<OrderPropsType>>;
    loading: boolean;
}) => {
    const [rows, setRows] = useState<data[]>([]);

    useEffect(() => {
        setRows(apiData);
    }, [apiData]);

    const StyledTableRow = styled(TableRow)(() => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    return (
        <TableContainer
            sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: "100%" },
                minWidth: { sm: "500px" },
            }}
        >
            <Table
                sx={{
                    width: "100%",
                    mx: "auto",
                }}
            >
                <BalanceHeadRow
                    order={orderProps.order}
                    orderBy={orderProps.orderBy}
                    headRow={headRow}
                    setOrderProps={setOrderProps}
                />
                <TableBody>
                    {loading ? (
                        <LoadingTable colspan={4} />
                    ) : apiData.length > 0 ? (
                        rows.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                >
                                    {row.id}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.created_at}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row.msg}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {row.difference}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <EmptyTable msg="No data" colSpan={4} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BalancePageTable;
