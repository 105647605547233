import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import PopupModelWrapper from "../wrappers/PopupModelWrapper";
import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material";
import PaginationTable from "../table/PaginationTable";
import { HeadColType } from "src/types/paginationTypes";
import SuccessStatusChip from "../common/SuccessStatusChip";
import { ProgressStatusEnum } from "src/enums/allBidsPageEnums";
import PopupButton from "../common/PopupButton";
import { ResponsePopupDataType } from "src/types/allBidsPageTypes";
import { useNavigate } from "react-router-dom";
import useMessage from "src/hooks/useMessage";
import useBidsPopupState from "src/hooks/useBidsPopupState";

const rowsPerPage = 4;

const AllBidsResponseModel = ({
    open,
    setOpen,
    setReloadData,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setReloadData: Dispatch<SetStateAction<number>>;
}) => {
    const navigate = useNavigate();
    const { frontendErrors } = useBidsPopupState();
    const { showSuccess } = useMessage();
    const headRow: HeadColType[] = [
        {
            label: "Domain",
            align: "center",
        },
        {
            label: "Status",
            align: "center",
        },
        {
            label: "Message",
            align: "center",
        },
    ];
    const handleClose = () => {
        setOpen(false);
        if (totalResults.success > 0) {
            if (window.location.pathname === "/dashboard/preBids") {
                setReloadData(Date.now() / 1000);
            } else {
                navigate("/dashboard/preBids");
            }
            showSuccess("Domains Added Successfully");
        }
    };
    const [tableData, setTableData] = useState<ResponsePopupDataType[]>([]);
    const [totalResults, setTotalResults] = useState<{
        success: number;
        failed: number;
        total: number;
    }>({
        success: 0,
        total: 0,
        failed: 0,
    });
    const [page, setPage] = React.useState(0);

    useEffect(() => {
        const results = {
            success: 0,
            total: 0,
            failed: 0,
        };
        frontendErrors.forEach((item) => {
            results.total += 1;
            if (item.success) results.success += 1;
            else results.failed += 1;
        });
        setTotalResults(results);
        setTableData(frontendErrors);
    }, [frontendErrors]);
    return (
        <PopupModelWrapper
            open={open}
            handleClose={handleClose}
            width={{ md: 650, sm: 350, xs: 280 }}
            title="Response Status"
        >
            <Stack sx={{ mx: 2.5, my: 2 }}>
                <Stack direction="row" sx={{ width: "auto" }} spacing={2}>
                    <CountChip
                        type="success"
                        value={`${totalResults.success}/${totalResults.total}`}
                        name="Success"
                    />
                    <CountChip
                        type="danger"
                        value={`${totalResults.failed}/${totalResults.total}`}
                        name="Failed"
                    />
                </Stack>
                <Box sx={{ mt: 3 }}>
                    <PaginationTable
                        rowsCount={tableData.length}
                        rowsPerPage={rowsPerPage}
                        headRow={headRow}
                        minWidth={500}
                        page={page + 1}
                        setPage={setPage}
                    >
                        {tableData.length > 0
                            ? (rowsPerPage > 0
                                  ? tableData.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                  : tableData
                              ).map((row: ResponsePopupDataType) => (
                                  <TableRow
                                      key={row.domain}
                                      sx={{
                                          "&:last-child td, &:last-child th": {
                                              border: 0,
                                          },
                                          height: "70px",
                                      }}
                                  >
                                      <TableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                      >
                                          {row.domain}
                                      </TableCell>
                                      <TableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                      >
                                          <SuccessStatusChip
                                              type={
                                                  row.success
                                                      ? ProgressStatusEnum.SUCCESS
                                                      : ProgressStatusEnum.FAILURE
                                              }
                                          />
                                      </TableCell>
                                      <TableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                          sx={{
                                              color: row.success
                                                  ? "#52C41A"
                                                  : "#FF4D4F",
                                          }}
                                      >
                                          {row.reason}
                                      </TableCell>
                                  </TableRow>
                              ))
                            : "No Data"}
                    </PaginationTable>
                </Box>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ mx: 2, my: 1 }}
            >
                <PopupButton
                    triggerFunction={handleClose}
                    type="proceed"
                    height="35px"
                >
                    Done
                </PopupButton>
            </Stack>
        </PopupModelWrapper>
    );
};

// Success Failed chips in top of the popup
const CountChip = ({
    name,
    value,
    type,
}: {
    name: string;
    value: string;
    type: "success" | "danger";
}) => {
    const colorStyles = {
        bgcolor: type === "success" ? "#F6FFED" : "#FFF1F0",
        color: type === "success" ? "#52C41A" : "#FF4D4F",
    };
    return (
        <Box
            sx={{
                ...colorStyles,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                px: 1.5,
                py: 1,
                borderRadius: 1,
            }}
        >
            <Typography sx={{ fontSize: "13px", mr: 2 }}>{name}</Typography>
            <Typography sx={{ fontSize: "13px", ml: 2 }}>{value}</Typography>
        </Box>
    );
};

export default AllBidsResponseModel;
