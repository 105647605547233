export interface InputSectionType {
    name: string;
    company: string;
    street: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    phone: string | undefined;
    email: string;
}

export interface InputSectionTypeOptional extends InputSectionType {
    sameAsOwner: boolean;
}

export interface NameserverInputSectionType {
    nameserver1: string;
    nameserver2: string;
}

export interface FormDataType {
    ownerInfo: InputSectionType;
    adminInfo: InputSectionTypeOptional;
    technicalContact: InputSectionTypeOptional;
    billingContact: InputSectionTypeOptional;
    nameserverSettings: NameserverInputSectionType;
    allowDailyEmailReports: boolean;
}

export enum ToastMessages {
    UPDATE_FAILED = "Failed to update profile data",
    UPDATE_SUCCESS = "Successfully updated profile data",
    COMPLETE_PROFILE = "Please complete your profile",
}

export enum InputSectionTag {
    OWNER_INFO = "ownerInfo",
    ADMIN_INFO = "adminInfo",
    TECHNICAL_INFO = "technicalInfo",
    BILLING_INFO = "billingInfo",
    NAMESERVERS = "nameservers",
}
