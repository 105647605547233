import { contentFontSize } from "../SharedStyles";

export const cardStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 0,
    borderWidth: 0,
    backgroundColor: "#fff8e6",
    paddingX: { xs: "20px", sm: "30px", md: "50px", lg: "60px" },
    paddingY: { xs: "20px", sm: "25px", md: "35px", lg: "45px" },
};

export const cardMediaStyle = {
    objectFit: "contain",
    objectPosition: "top",
    maxWidth: { xs: "180px", sm: "250px", md: "350px" },
    height: "auto",
    aspectRatio: "1 / 1",
};

export const cardContentStyle = {
    display: "flex",
    flexDirection: "column",
};

export const cardContentHeaderStyle = {
    font: "Inter",
    fontSize: {
        xs: "16px",
        sm: "20px",
        md: "22px",
        lg: "28px",
    },
    fontWeight: 600,
    mb: { xs: 0, md: "20px" },
};

export const cardContentBodyStyle = {
    font: "Inter",
    fontSize: contentFontSize,
    lineHeight: { xs: "20px", sm: "25px" },
    color: "#615c61",
};

export const gridItemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
