import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Pagination, Stack, TableHead } from "@mui/material";
import { HeadColType } from "src/types/paginationTypes";
import { Dispatch, SetStateAction } from "react";

const PaginationTable = ({
    rowsCount,
    rowsPerPage,
    minWidth,
    children,
    headRow,
    page,
    setPage,
}: {
    rowsCount: number;
    rowsPerPage: number;
    minWidth?: number;
    headRow: HeadColType[];
    children: React.ReactNode;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
}) => {
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage - 1);
        console.log(Math.ceil(rowsCount / rowsPerPage), newPage - 1);
    };

    return (
        <TableContainer>
            <Table
                sx={{ minWidth: minWidth ? minWidth : "max-content" }}
                aria-label="custom pagination table"
            >
                <TableHead sx={{ bgcolor: "#0000000F" }}>
                    <TableRow>
                        {headRow.map((col) => (
                            <TableCell
                                key={col.label}
                                align={col.align ?? "right"}
                            >
                                {col.component ? col.component : col.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{children}</TableBody>
            </Table>
            {rowsCount > 0 && (
                <Stack
                    direction="row"
                    justifyContent={{ md: "flex-end", sm: "flex-end" }}
                    sx={{ width: "100%" }}
                >
                    <Pagination
                        count={Math.ceil(rowsCount / rowsPerPage)} // total pages
                        variant="outlined"
                        shape="rounded"
                        page={page} // Current page
                        onChange={handleChangePage}
                        boundaryCount={1}
                        siblingCount={0}
                    />
                </Stack>
            )}
        </TableContainer>
    );
};

export default PaginationTable;
