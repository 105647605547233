import { Backdrop, Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LinearProgress from "@mui/material/LinearProgress";

function DisabledPopup({ openDisabledPopup }: { openDisabledPopup: boolean }) {
    return (
        <Backdrop
            sx={(theme) => ({
                color: "#fff",
                zIndex: theme.zIndex.drawer + 1000,
            })}
            open={openDisabledPopup}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                    bgcolor: "white",
                    color: "black",
                    padding: 3,
                    borderRadius: 3,
                }}
            >
                <ErrorOutlineIcon
                    fontSize="large"
                    sx={{ marginBottom: 1, color: "red" }}
                />
                <Typography>
                    {"Your account has been "}
                    <span style={{ color: "red" }}>{"disabled !"}</span>
                </Typography>

                <Typography>
                    {"You will be automatically "}
                    <span style={{ color: "red" }}>{"logged out."}</span>
                </Typography>
                <Typography>
                    {`Please contact ${
                        process.env.REACT_APP_DEFAULT_USER_EMAIL ?? "an Admin."
                    }`}
                </Typography>
                <Box sx={{ width: "80%", marginTop: 2 }}>
                    <LinearProgress />
                </Box>
            </Box>
        </Backdrop>
    );
}

export default DisabledPopup;
