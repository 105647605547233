export const navContentStyle = {
    outerBox: {
        maxHeight: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    logoHolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    navLinkHolder: {
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-around",
        position: { xs: "fixed", md: "initial" },
        width: { xs: "100%", md: "auto" },
        bgcolor: { xs: "black", md: "transparent" },
        transition: "all 0.5s",
        zIndex: { xs: -2, md: 0 },
    },
};

export const navToggleIcon = {
    bar: {
        width: "20px",
        height: "3px",
        bgcolor: "#EFD016",
        borderRadius: 10,
        my: "2px",
        transition: "all 0.5s",
    },
};

export const avatarButton = {
    menuBox: {
        position: "absolute",
        width: "200px",
        bgcolor: "black",
        right: 5,
        top: "30px",
        borderRadius: 1,
        border: 1,
        borderColor: "white",
        px: 1,
    },
    menuItem: {
        fontSize: {
            xs: "12px",
            md: "14px",
            cursor: "pointer",
        },
        p: 1,
        "&:hover": {
            color: "#EFD016",
        },
    },
};
