import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import CircleIcon from "@mui/icons-material/Circle";
import {
    bulletStyle,
    closeIconStyle,
    NotificationBoxStyle,
    TypographyContentStyle,
    TypographyHeaderStyle,
} from "./NotificationPopup.style";
import useData from "src/hooks/useApi";
import CloseIcon from "@mui/icons-material/Close";

function NotificationPopup() {
    const auth = useAuth();
    const api = useData();

    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

    const [latestNotification, setLatestNotification] = useState<{
        header: string;
        content: string[];
    }>();

    const convertUrlsToLinks = (text: string): JSX.Element => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls: string[] = [];
        let nonUrlText = text;

        let match;
        while ((match = urlRegex.exec(text)) !== null) {
            urls.push(match[0]);
        }

        nonUrlText = text.replace(urlRegex, "").replace(/\s+/g, " ").trim();

        return (
            <Typography
                component="span"
                variant="body1"
                style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {nonUrlText}
                {urls.length > 0 && nonUrlText.length > 0 && " "}
                {urls.map((url, index) => (
                    <Fragment key={url}>
                        <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                                color: "#FFB700",
                                cursor: "pointer",
                            }}
                        >
                            {url}
                        </a>
                        {index < urls.length - 1 && ", "}
                    </Fragment>
                ))}
            </Typography>
        );
    };

    async function readLatestNotification() {
        try {
            const notification: any = await api.fetchData(
                "notifications/getLiveNotification",
            );

            if (notification.data) {
                setLatestNotification(notification.data);
                setIsPopupOpen(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!auth.isLogged) {
            setIsPopupOpen(false);
            setLatestNotification(undefined);
        } else {
            readLatestNotification();
        }
    }, [auth.isLogged]);

    return latestNotification ? (
        <Modal open={isPopupOpen}>
            <Box sx={NotificationBoxStyle}>
                <IconButton
                    sx={closeIconStyle}
                    onClick={() => setIsPopupOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={TypographyHeaderStyle}>
                    {latestNotification.header}
                </Typography>
                <Box
                    sx={{
                        overflowY: "auto",
                        minWidth: "100%",
                        marginTop: "5px",
                    }}
                >
                    {latestNotification.content.map((notification, index) => (
                        <Grid
                            key={index}
                            container
                            columnSpacing={1}
                            marginBottom={1}
                        >
                            <Grid item>
                                <CircleIcon style={bulletStyle} />
                            </Grid>
                            <Grid item xs={11} sx={TypographyContentStyle}>
                                {convertUrlsToLinks(notification)}
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Box>
        </Modal>
    ) : null;
}

export default NotificationPopup;
