import { Box, Button, Typography } from "@mui/material";
import TopUpChipButton from "src/components/balance/TopUpChipButton";
import {
    balanceTitle,
    insideBoxTypography,
    topUpButton,
    topUpInsideBox,
    topUpSelectionChips,
    topUpSelectionRightBox,
} from "./BalancePage.style";
import { useEffect, useState } from "react";
import BalancePageTable from "src/components/balance/BalancePageTable";
import { OrderPropsType, data } from "src/types/balancePageTypes";
import useData from "src/hooks/useApi";
import useBalance from "src/hooks/useBalance";
import TablePagination from "src/components/table/TablePagination";
import { PaginationPropsType } from "src/types/paginationTypes";
import { calcPaginationProps } from "src/utils/helperFunctions";
import { OrderTypes } from "src/enums/sortingsEnum";
import useAuth from "../../hooks/useAuth";
import {
    dataPopupWebhookReceived,
    setupFastspring,
    dataPopupClosed,
    dataErrorCallback,
} from "../../utils/fastspringUtils";
import useMessage from "../../hooks/useMessage";
import { mainContentContainer } from "src/components/SharedStyles";
import useNotification from "src/hooks/useNotification";

const BalancePage = () => {
    const api = useData();
    const balance = useBalance();
    const auth = useAuth();
    const { showSuccess } = useMessage();
    const lastBalanceUpdateTime = useNotification();

    // @ts-expect-error expecting an error
    const fastspring = window.fastspring;

    const [activeChipButton, setActiveChipButton] = useState<number>(50);
    const [tableData, setTableData] = useState<data[]>([]);
    const [paginationProps, setPaginationProps] = useState<PaginationPropsType>(
        {
            pages: 0,
            rowsPerPage: 10,
            currentPage: 1,
        },
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [orderProps, setOrderProps] = useState<OrderPropsType>({
        order: OrderTypes.DESC,
        orderBy: "created_at",
    });

    async function getData(pageReset: boolean = false) {
        setLoading(true);
        const payload = pageReset
            ? {
                  page: 1,
                  size: paginationProps.rowsPerPage,
                  sort: {
                      key: orderProps.orderBy,
                      order: orderProps.order?.toUpperCase(),
                  },
              }
            : {
                  page: paginationProps.currentPage,
                  size: paginationProps.rowsPerPage,
                  sort: {
                      key: orderProps.orderBy,
                      order: orderProps.order?.toUpperCase(),
                  },
              };
        try {
            const response: any = await api?.postData(
                "/transaction/getUserTransactionData",
                payload,
            );
            balance?.setBalance(
                response && response.currentBalance
                    ? response.currentBalance
                    : "0",
            );
            if (response.data) {
                setTableData(response.data);
            } else {
                setTableData([]);
            }
            setPaginationProps((paginationProps) =>
                calcPaginationProps(paginationProps, response.count),
            );
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const buyProduct = () => {
        if (fastspring?.builder) {
            setupFastspring(
                fastspring,
                activeChipButton,
                auth.user?.email || "",
                auth.user?.username || "",
            );
        }
    };

    useEffect(() => {
        getData();
    }, [
        paginationProps.currentPage,
        orderProps.orderBy,
        orderProps.order,
        lastBalanceUpdateTime,
    ]);

    // Page reset after page row count changed
    useEffect(() => {
        setPaginationProps((paginationProps) => ({
            ...paginationProps,
            currentPage: 1,
        }));
        getData(true);
    }, [paginationProps.rowsPerPage]);

    useEffect(() => {
        // @ts-expect-error not an error
        window.dataErrorCallback = (code: number, message: string) =>
            dataErrorCallback(code, message, auth);
        // @ts-expect-error not an error
        window.dataPopupWebhookReceived = (details: any) =>
            dataPopupWebhookReceived(details, activeChipButton, auth, api);
        // @ts-expect-error not an error
        window.dataPopupClosed = (details: any) =>
            dataPopupClosed(details, showSuccess);
        console.log(fastspring);
    }, [fastspring, activeChipButton]);

    return (
        <>
            <Box sx={mainContentContainer}>
                <Box sx={topUpSelectionRightBox}>
                    <Box sx={topUpInsideBox}>
                        <Typography sx={insideBoxTypography}>
                            Current Balance
                        </Typography>
                        <Typography
                            sx={{
                                ...insideBoxTypography,
                                fontSize: { xs: "12px", sm: "16px" },
                            }}
                        >
                            {balance.currentBalance
                                ? balance.currentBalance.toFixed(2)
                                : "0.00"}
                            {" USD"}
                        </Typography>
                    </Box>
                    {/* Change to lowercase */}
                </Box>
                <Box flex={1}>
                    <Typography sx={balanceTitle}>
                        Select Your Top-up Amount
                    </Typography>
                    <Box sx={topUpSelectionChips}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "space-around",
                                    md: "start",
                                },
                                flexWrap: "wrap",
                                alignItems: "center",
                            }}
                        >
                            <TopUpChipButton
                                activeValue={activeChipButton}
                                setActiveChipButton={setActiveChipButton}
                            >
                                {20}
                            </TopUpChipButton>
                            <TopUpChipButton
                                activeValue={activeChipButton}
                                setActiveChipButton={setActiveChipButton}
                            >
                                {50}
                            </TopUpChipButton>
                            <TopUpChipButton
                                activeValue={activeChipButton}
                                setActiveChipButton={setActiveChipButton}
                            >
                                {100}
                            </TopUpChipButton>
                            <TopUpChipButton
                                activeValue={activeChipButton}
                                setActiveChipButton={setActiveChipButton}
                            >
                                {200}
                            </TopUpChipButton>
                            <TopUpChipButton
                                activeValue={activeChipButton}
                                setActiveChipButton={setActiveChipButton}
                            >
                                {500}
                            </TopUpChipButton>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                sx={topUpButton}
                                onClick={() => {
                                    buyProduct();
                                }}
                            >
                                Top-up
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box alignItems="center" sx={mainContentContainer}>
                <BalancePageTable
                    apiData={tableData}
                    orderProps={orderProps}
                    setOrderProps={setOrderProps}
                    loading={loading}
                />
                <TablePagination
                    paginationProps={paginationProps}
                    setPaginationProps={setPaginationProps}
                />
            </Box>
        </>
    );
};

export default BalancePage;
