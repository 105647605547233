import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { dateTimeConvertor } from "src/utils/helperFunctions";
import EmptyTable from "../table/EmptyTable";
import {
    data,
    OrderPropsType,
    postAuctionHeading,
    resultsHeading,
    ResultTableData,
} from "src/types/postAuctionTypes";
import LoadingTable from "../table/LoadingTable";
import ProgressStatusChip from "../common/ProgressStatusChip";
import Big from "big.js";
import {
    AuctionBackorderEnum,
    AuctionTypeEnum,
    UserStatusEnum,
} from "../../enums/allBidsPageEnums";
import PostAuctionHeadRow from "../postAuction/PostAuctionHeadRow";
import { StyledTableCell } from "../SharedStyles";

const ResultsTable = ({
    apiData,
    orderProps,
    setOrderProps,
    loading,
}: {
    apiData: data[];
    orderProps: OrderPropsType;
    setOrderProps: Dispatch<SetStateAction<OrderPropsType>>;
    loading?: boolean;
}) => {
    const [rows, setRows] = useState<ResultTableData[]>([]);

    useEffect(() => {
        const convertedData: ResultTableData[] = apiData.map((item) => ({
            ...item,
            auctionEnd: item.auctionEnd
                ? `${dateTimeConvertor(+item.auctionEnd).date} ${
                      dateTimeConvertor(+item.auctionEnd).time
                  }`
                : "",
            amount: new Big(item.amount),
            winningPrice: new Big(item.winningPrice),
        }));
        setRows(convertedData);
    }, [apiData]);

    const StyledTableRow = styled(TableRow)(() => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    return (
        <TableContainer
            sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: "100%" },
                minWidth: { sm: "500px" },
            }}
        >
            <Table
                sx={{
                    width: "100%",
                    mx: "auto",
                }}
            >
                <PostAuctionHeadRow
                    order={orderProps.order}
                    orderBy={orderProps.orderBy}
                    headRow={resultsHeading}
                    setOrderProps={setOrderProps}
                />
                <TableBody>
                    {loading ? (
                        <LoadingTable colspan={postAuctionHeading.length} />
                    ) : apiData.length > 0 ? (
                        rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                >
                                    {row.domainName}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.auctionType == AuctionTypeEnum.PRE
                                        ? AuctionBackorderEnum.BAC
                                        : AuctionBackorderEnum.AUC}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.status === UserStatusEnum.SUCCESS
                                        ? row.winningPrice.toFixed(2)
                                        : row.amount.toFixed(2)}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <ProgressStatusChip
                                        chipType={
                                            row.isArchived === true
                                                ? UserStatusEnum.ARCHIVED
                                                : row.status
                                        }
                                        tooltip={
                                            row.status ===
                                            UserStatusEnum.SUCCESS
                                                ? "The domain can be managed using your namerider.com account (use your AutoBackorder credentials to login)"
                                                : undefined
                                        }
                                        onClick={
                                            row.status ===
                                            UserStatusEnum.SUCCESS
                                                ? () => {
                                                      // open namerider.com in new tab
                                                      window.open(
                                                          "https://namerider.com",
                                                          "_blank",
                                                      );
                                                  }
                                                : undefined
                                        }
                                    />
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.auctionEnd}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    {row.bidders}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <EmptyTable
                            msg="No data"
                            colSpan={postAuctionHeading.length}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ResultsTable;
