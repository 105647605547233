import axios, { AxiosInstance } from "axios";
import { getToken, TokenTypeEnum } from "./jwtFunctions";

const api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use((config) => {
    const token = getToken(TokenTypeEnum.AUTH);
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export default api;
